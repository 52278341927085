<div class="info-body" (scroll)="onScroll()" fxFill>
    <div class="info-content">
        <div id="info-top"></div>
        <div class="info-heading">
            <span *ngIf="!hideSubTitle" class="sub-heading" [innerHtml]="subTitle ? subTitle : ''"></span>
            <div class="cite-button-wrapper">
                <button *ngIf="state === 'full'" [title]="lang === 'de' ? 'Metadaten' : 'Metadata'" mat-icon-button class="cite-button" (click)="citeClick.emit()">
                    <span class="cm-square-quote-alt"></span>
                </button>
            </div>
            <h1 [innerHtml]="title"></h1>
        </div>
        <div *ngIf="state === 'short'; else fullInfo" class="intro">
            <cm-information [content]="abstract" [lang]="lang"></cm-information>
            <button *ngIf="!hideReadMoreText" mat-flat-button class="btn-read-more" color="warn" (click)="moreClick.emit()">{{ readMoreText }}</button>
        </div>
        <ng-template #fullInfo>
            <div *ngIf="content">
                <cm-information *ngIf="content && media" [content]="content" [lang]="lang" [media]="media"></cm-information>
            </div>
        </ng-template>
    </div>
    <div class="bottom-white"></div>
</div>
