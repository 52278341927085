<div class="ngx-gallery-thumbnails-wrapper ngx-gallery-thumbnail-size-{{size}}">
  <div class="ngx-gallery-thumbnails" [style.transform]="'translateX(' + thumbnailsLeft + ')'"
       [style.marginLeft]="thumbnailsMarginLeft">
    <a [href]="hasLink(i) ? links[i] : '#'" [target]="linkTarget" class="ngx-gallery-thumbnail"
       *ngFor="let image of getImages(); let i = index;"
       (click)="handleClick($event, i)" [style.width]="getThumbnailWidth()" [style.height]="getThumbnailHeight()"
       [style.left]="getThumbnailLeft(i)" [style.top]="getThumbnailTop(i)"
       [ngClass]="{ 'ngx-gallery-active': i == selectedIndex, 'ngx-gallery-clickable': clickable }"
       [attr.aria-label]="labels[i]">
       <div *ngIf="getFileType(image) == 'image'" [style.background-image]="getSafeUrl(image)" class="ngx-gallery-thumbnail"
       [ngClass]="{ 'ngx-gallery-active': i == selectedIndex, 'ngx-gallery-clickable': clickable }"
       style="width: 100%; height: 100%; position:absolute;"></div>
       <div *ngIf="getFileType(image) == 'video'" class="ngx-gallery-thumbnail-video">
        <video  class="ngx-gallery-thumbnail" [ngClass]="{ 'ngx-gallery-active': i == selectedIndex, 'ngx-gallery-clickable': clickable }"
        style="width: 100%; height: 100%; position:absolute; left:0; background:#000;">
        <source [src]="image">
          Your browser does not support the video tag.
       </video>
      </div>
       <div class="ngx-gallery-icons-wrapper">
        <ngx-gallery-action *ngFor="let action of actions" [icon]="action.icon" [disabled]="action.disabled"
                            [titleText]="action.titleText" (closeClick)="action.onClick($event, i)"></ngx-gallery-action>
      </div>
      <div class="ngx-gallery-remaining-count-overlay"
           *ngIf="remainingCount && remainingCountValue && (i == (rows * columns) - 1)">
        <span class="ngx-gallery-remaining-count">+{{remainingCountValue}}</span>
      </div>
      <span class="cm-square-expand">
                </span>
    </a>
  </div>
</div>
<ngx-gallery-arrows *ngIf="canShowArrows()" (prevClick)="moveLeft()" (nextClick)="moveRight()"
                    [prevDisabled]="!canMoveLeft()" [nextDisabled]="!canMoveRight()" [arrowPrevIcon]="arrowPrevIcon"
                    [arrowNextIcon]="arrowNextIcon"></ngx-gallery-arrows>
