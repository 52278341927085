<span *ngIf="data">
    <button mat-icon-button class="control" (click)="toggle()">
        <span [ngClass]="{'cm-square-film':!opened,'cm-square-close':opened}">
        </span>
    </button>
    <div class="animation-container" [@container]="opened">
        <div class="container" fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="50" class="video">
                <div id="video-inline-{{id}}" #video></div>
            </div>
            <cm-caption *ngIf="openedFirst"  fxFlex="50" class="caption" [content]="lang === 'en' ? data.captionEn : data.captionDe" [lang]="lang"></cm-caption>
        </div>
    </div>
</span>
