import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'cm-team-page',
  templateUrl: './team-page.html',
  styleUrls: ['./team-page.scss']
})
export class TeamPageComponent implements OnInit {

  constructor(

  ) { }


  ngOnInit() {

  }

}
