<span *ngIf="data">
    <button mat-icon-button class="control" (click)="toggle()">
        <span [ngClass]="{'cm-square-audio':!opened,'cm-square-close':opened}"></span>
    </button>
    <div class="animation-container" [@container]="opened">
        <div *ngIf="openedFirst"  class="container" fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="50" class="image">
                <audio controls controlsList="nodownload" preload="none" (contextmenu)="onRightClick($event)">
                    <source [src]="this.data.data.full" type="audio/mpeg">
                </audio>
            </div>
            <cm-caption fxFlex="50" class="caption" [content]="lang === 'en' ? data.captionEn : data.captionDe" [lang]="lang"></cm-caption>
        </div>
    </div>
</span>

