import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-disclosure-page',
  templateUrl: './disclosure-page.html',
  styleUrls: ['./disclosure-page.scss']
})
export class DisclosurePageComponent implements OnInit {
    constructor(
    ) { }

    ngOnInit() {
    }


}
