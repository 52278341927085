
<div class="container">
<button mat-button class="close cm-close" (click)="close()"></button>
<div fxLayout="column" class="meta-info-container" *ngIf="translate.currentLang === 'en'">
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Citation</div>
        <div class="content citation-content"><span>Simon Ganahl: &quot;</span>
            <span innerHtml="{{ titleEn }}"></span><span>,&quot;</span>
            <span *ngIf="data.type === 'event'"> trans. by Katy Derbyshire,</span>
            <span *ngIf="data.type === 'mediator' && data.data.id !== '0'"> trans. by Maria Slater,</span>
            <span> last modified on {{ updatedEn }}, in:
            Simon Ganahl et al.: <i>Campus Medius</i>, 2014–{{currentYear}}, URL: </span>
            <span class="url">{{url}} </span><a class="copy" (click)="copyCitation()">(copy)</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Title</div>
        <div class="content">&quot;<span innerHtml="{{ titleEn }}"></span>&quot; in <i>Campus Medius</i></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">URL</div>
        <div class="content url">{{url}} <a class="copy" (click)="copyUrl()">(copy)</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Text</div>
        <div class="content"><cm-link-intern href="/team#ganahl" [text]="'Simon Ganahl'" (click)="close()"></cm-link-intern></div>
    </div>
    <div *ngIf="data.type === 'event'" fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Translation</div>
        <div class="content"><cm-link-intern href="/team#derbyshire" [text]="'Katy Derbyshire'" (click)="close()"></cm-link-intern></div>
    </div>
    <div *ngIf="data.type === 'mediator' && data.data.id !== '0'" fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Translation</div>
        <div class="content"><cm-link-intern href="/team#slater" [text]="'Maria Slater'" (click)="close()"></cm-link-intern></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Code</div>
        <div class="content"><cm-link-intern href="/team#krimbacher" [text]="'Andreas Krimbacher'" (click)="close()"></cm-link-intern></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Design</div>
        <div class="content"><cm-link-intern href="/team#kiesenhofer" [text]="'Susanne Kiesenhofer'" (click)="close()"></cm-link-intern></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Published</div>
        <div class="content">{{ publishedEn }}</div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Updated</div>
        <div class="content">{{ updatedEn }}</div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Abstract</div>
        <div class="content"><span innerHtml="{{ abstractExpanded ? abstractEn : abstractShortEn }}"></span>&nbsp;<a (click)="abstractExpanded = !abstractExpanded">({{ abstractExpanded ? 'show less' : 'show all' }})</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Keywords</div>
        <div class="content">{{ keywordsExpanded ? keywordsEnStr : keywordsShortEnStr }} <a *ngIf="keywordsShowExpanded" (click)="keywordsExpanded = !keywordsExpanded">({{ keywordsExpanded ? 'show less' : 'show all' }})</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Copyright</div>
        <div class="content">
            This website is licensed under Creative Commons Attribution&nbsp;4.0 (<cm-link-extern href="https://creativecommons.org/licenses/by/4.0/deed.en" [text]="'CC&nbsp;BY&nbsp;4.0'"></cm-link-extern>). This license permits unrestricted use and distribution of the respective material, provided that the creator(s), who retain(s) copyright, and the source are properly credited, ideally in the form suggested above. The reuse of works cited on this website from external sources (texts, images, audio, video) may require further permissions from the rights holder(s). The obligation to research and obtain such permissions lies with the reusing party.
        </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Funding</div>
        <div class="content">
            Based on international peer reviews, the production of this website has been supported by the Austrian Academy of Sciences (<cm-link-extern href="https://www.oeaw.ac.at/en/" text="ÖAW"></cm-link-extern>: APART 11810), the Austrian Science Fund (<cm-link-extern href="https://fwf.ac.at/en" text="FWF"></cm-link-extern>: J 3181-G20 &amp; PUD 17-G), the City of Vienna (<cm-link-extern href="https://www.wien.gv.at/kultur/abteilung/" text="MA 7"></cm-link-extern>: 573323/14), the <cm-link-extern href="https://www.univie.ac.at/germanistik/" text="Department of German Studies"></cm-link-extern> at the University of Vienna, and the digital repository <cm-link-extern href="https://phaidra.univie.ac.at/" text="Phaidra"></cm-link-extern>.
        </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div *ngIf="!data.isMobile" class="label">
            <button class="download-button" mat-icon-button (click)="downloadJsonLd()">
                <span class="cm-square-download"></span>
            </button>
        </div>
        <div class="content jsonldcontent"><a (click)="downloadJsonLd()">
            <div *ngIf="data.isMobile" class="inline-jsonld-button">
            <button class="download-button" mat-icon-button (click)="downloadJsonLd()">
                <span class="cm-square-download"></span>
            </button>
            </div>Download metadata in JSON-LD format</a></div>
    </div>
</div>

<div fxLayout="column" class="meta-info-container" *ngIf="translate.currentLang === 'de'">
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Zitation</div>
        <div class="content citation-content">Simon Ganahl: &quot;<span innerHtml="{{ titleDe }}"></span>&quot;, zuletzt aktualisiert am {{ updatedDe }}, in:
            Simon Ganahl u.a.: <i>Campus Medius</i>, 2014–{{currentYear}}, URL: <span class="url">{{url}} </span><a class="copy" (click)="copyCitation()">(kopieren)</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Titel</div>
        <div class="content">&quot;<span innerHtml="{{ titleDe }}"></span>&quot; in <i>Campus Medius</i></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">URL</div>
        <div class="content url">{{url}} <a class="copy" (click)="copyUrl()">(kopieren)</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Text</div>
        <div class="content"><cm-link-intern href="/team#ganahl" [text]="'Simon Ganahl'" (click)="close()"></cm-link-intern></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Code</div>
        <div class="content"><cm-link-intern href="/team#krimbacher" [text]="'Andreas Krimbacher'" (click)="close()"></cm-link-intern></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Design</div>
        <div class="content"><cm-link-intern href="/team#kiesenhofer" [text]="'Susanne Kiesenhofer'" (click)="close()"></cm-link-intern></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Publiziert</div>
        <div class="content">{{ publishedDe }}</div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Aktualisiert</div>
        <div class="content">{{ updatedDe }}</div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Abstract</div>
        <div class="content"><span innerHtml="{{ abstractExpanded ? abstractDe : abstractShortDe }}"></span>&nbsp;<a (click)="abstractExpanded = !abstractExpanded">({{ abstractExpanded ? 'weniger anzeigen' : 'alles anzeigen' }})</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Schlagwörter</div>
        <div class="content">{{ keywordsExpanded ? keywordsDeStr : keywordsShortDeStr }} <a *ngIf="keywordsShowExpanded" (click)="keywordsExpanded = !keywordsExpanded">({{ keywordsExpanded ? 'weniger anzeigen' : 'alle anzeigen' }})</a></div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Urheberrecht</div>
        <div class="content">
            Diese Website ist lizenziert unter Creative Commons Namensnennung&nbsp;4.0 (<cm-link-extern href="https://creativecommons.org/licenses/by/4.0/deed.de" [text]="'CC&nbsp;BY&nbsp;4.0'"></cm-link-extern>). Diese Lizenz erlaubt die uneingeschränkte Nutzung und Verbreitung des entsprechenden Materials unter der Bedingung, dass die UrheberInnen, bei denen alle Rechte verbleiben, und die Quelle eindeutig genannt werden, idealerweise in der oben vorgeschlagenen Form. Die Wiederverwendung von auf dieser Website zitierten Werken aus externen Quellen (Texte, Bilder, Ton- und Filmaufnahmen) erfordert ggf. weitere Nutzungsgenehmigungen durch die jeweiligen RechteinhaberInnen. Die Verpflichtung, solche Genehmigungen einzuholen, liegt bei der wiederverwendenden Partei.
        </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class="label">Förderungen</div>
        <div class="content">
            Basierend auf internationalen Begutachtungen (<i>peer reviews</i>), wurde die Herstellung dieser Website gefördert von der Österreichischen Akademie der Wissenschaften (<cm-link-extern href="https://www.oeaw.ac.at/" text="ÖAW"></cm-link-extern>: APART 11810), dem Österreichischen Wissenschaftsfonds (<cm-link-extern href="https://fwf.ac.at/" text="FWF"></cm-link-extern>: J 3181-G20 &amp; PUD 17-G), der Stadt Wien (<cm-link-extern href="https://www.wien.gv.at/kultur/abteilung/" text="MA 7"></cm-link-extern>: 573323/14), dem <cm-link-extern href="https://www.univie.ac.at/germanistik/" text="Institut für Germanistik"></cm-link-extern> der Universität Wien sowie dem digitalen Repositorium <cm-link-extern href="https://phaidra.univie.ac.at/" text="Phaidra"></cm-link-extern>.
        </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
        <div *ngIf="!data.isMobile" class="label">
            <button class="download-button" mat-icon-button (click)="downloadJsonLd()">
                <span class="cm-square-download"></span>
            </button>
        </div>
        <div class="content jsonldcontent"><a (click)="downloadJsonLd()"><div *ngIf="data.isMobile" class="inline-jsonld-button">
            <button class="download-button" mat-icon-button (click)="downloadJsonLd()">
                <span class="cm-square-download"></span>
            </button>
            </div>Metadaten im Format JSON-LD herunterladen</a></div>
    </div>
</div>
</div>