import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-about-page',
  templateUrl: './about-page.html',
  styleUrls: ['./about-page.scss']
})
export class AboutPageComponent implements OnInit {
    constructor(
    ) { }

    ngOnInit() {
    }


}
