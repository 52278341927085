<cm-start-page-selector></cm-start-page-selector>

<cm-sidepanel *ngIf="!isMobile">
  <div class="title-header" fxLayout="row" fxLayoutAlign=" center" [@titleHeader]="showTitleHeader">
      <button class="cite-button" [title]="translate.currentLang === 'de' ? 'Metadaten' : 'Metadata'" mat-icon-button (click)="showCite()">
          <span class="cm-square-quote-alt"></span>
      </button>
      <h2 [innerHtml]="translate.currentLang === 'de' ? page.titleDe : page.titleEn"></h2>
  </div>
  <div class="up-header" [@titleHeader]="showTitleHeader">
      <button mat-icon-button class="back-to-top" (click)="scrollUp()">
          <span class="cm-back-to-top"></span>
      </button>
  </div>
  <div *ngIf="page" class="info-container">
    <cm-info-container
     [lang]="translate.currentLang"
     [title]="translate.currentLang === 'de' ? page.titleDe : page.titleEn"
     [state]="'full'"
     [content]="translate.currentLang === 'de' ? page.information.contentDe : page.information.contentEn"
     [media]="page.information.media"
     (citeClick)="showCite()"
     (sectionChange)="sectionChange($event)"
     (showTitleHeader)="showTitleHeader = $event">
    </cm-info-container>
  </div>
</cm-sidepanel>

<div *ngIf="isMobile" class="mobile-short-info">
  <cm-start-page-selector></cm-start-page-selector>
  <div *ngIf="page" class="info">
      <div class="mobile-abstract">
          <div [innerHTML]="translate.currentLang === 'de' ? overviewPage.mobileAbstractDe : overviewPage.mobileAbstractEn"></div>
      </div>
      <div class="intro">
          <button mat-flat-button (click)="mobileShowMore()" class="btn-read-more" color="warn">{{'info.read-more' | translate | uppercase}}</button>
      </div>
  </div>
</div>

<cm-info-container-mobile
*ngIf="isMobile" class="mobile-info"
[lang]="translate.currentLang"
[title]="translate.currentLang === 'de' ? page.titleDe : page.titleEn"
[content]="translate.currentLang === 'de' ? page.information.contentDe : page.information.contentEn"
[media]="page.information.media"
[showHeader]="showTitleHeaderMobile"
(citeClick)="showCite()"
(sectionChange)="sectionChange($event)"
(sectionChange)="sectionChange($event)"
(showShort)="mobileShowShort()"
(galleryClosed)="galleryClosed($event)"
(galleryOpened)="galleryOpened($event)">
</cm-info-container-mobile>