import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-book-page',
  templateUrl: './book-page.html',
  styleUrls: ['./book-page.scss']
})
export class BookPageComponent implements OnInit {

  constructor(
  ) { }


  ngOnInit() {

  }
}
