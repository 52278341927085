<div class="ngx-gallery-layout {{currentOptions?.layout}}">
  <ngx-gallery-image *ngIf="currentOptions?.image" [style.height]="getImageHeight()" [images]="mediumImages"
                     [clickable]="currentOptions?.preview" [selectedIndex]="selectedIndex"
                     [arrows]="currentOptions?.imageArrows" [arrowsAutoHide]="currentOptions?.imageArrowsAutoHide"
                     [arrowPrevIcon]="currentOptions?.arrowPrevIcon" [arrowNextIcon]="currentOptions?.arrowNextIcon"
                     [swipe]="currentOptions?.imageSwipe" [animation]="currentOptions?.imageAnimation"
                     [size]="currentOptions?.imageSize" [autoPlay]="currentOptions?.imageAutoPlay"
                     [autoPlayInterval]="currentOptions?.imageAutoPlayInterval"
                     [autoPlayPauseOnHover]="currentOptions?.imageAutoPlayPauseOnHover"
                     [infinityMove]="currentOptions?.imageInfinityMove" [lazyLoading]="currentOptions?.lazyLoading"
                     [actions]="currentOptions?.imageActions" [descriptions]="descriptions"
                     [showDescription]="currentOptions?.imageDescription" [bullets]="currentOptions?.imageBullets"
                     (imageClick)="openPreview($event)" (activeChange)="selectFromImage($event)" (animating)="setAnimating($event)"></ngx-gallery-image>

  <ngx-gallery-thumbnails *ngIf="currentOptions?.thumbnails" [style.marginTop]="getThumbnailsMarginTop()"
                          [style.marginBottom]="getThumbnailsMarginBottom()" [style.height]="getThumbnailsHeight()"
                          [images]="smallImages" [isAnimating]="isAnimating" [links]="currentOptions?.thumbnailsAsLinks ? links : []"
                          [labels]="labels" [linkTarget]="currentOptions?.linkTarget" [selectedIndex]="selectedIndex"
                          [columns]="currentOptions?.thumbnailsColumns" [rows]="currentOptions?.thumbnailsRows"
                          [margin]="currentOptions?.thumbnailMargin" [arrows]="currentOptions?.thumbnailsArrows"
                          [arrowsAutoHide]="currentOptions?.thumbnailsArrowsAutoHide"
                          [arrowPrevIcon]="currentOptions?.arrowPrevIcon"
                          [arrowNextIcon]="currentOptions?.arrowNextIcon"
                          [clickable]="currentOptions?.image || currentOptions?.preview"
                          [swipe]="currentOptions?.thumbnailsSwipe" [size]="currentOptions?.thumbnailSize"
                          [moveSize]="currentOptions?.thumbnailsMoveSize" [order]="currentOptions?.thumbnailsOrder"
                          [remainingCount]="currentOptions?.thumbnailsRemainingCount"
                          [lazyLoading]="currentOptions?.lazyLoading" [actions]="currentOptions?.thumbnailActions"
                          (activeChange)="selectFromThumbnails($event)" [ngClass]="currentOptions?.thumbnailClasses"></ngx-gallery-thumbnails>

  <ngx-gallery-preview [images]="bigImages" [types]="types" [descriptions]="descriptions"
                       [showDescription]="currentOptions?.previewDescription"
                       [arrowPrevIcon]="currentOptions?.arrowPrevIcon" [arrowNextIcon]="currentOptions?.arrowNextIcon"
                       [closeIcon]="currentOptions?.closeIcon" [fullscreenIcon]="currentOptions?.fullscreenIcon"
                       [spinnerIcon]="currentOptions?.spinnerIcon" [arrows]="currentOptions?.previewArrows"
                       [arrowsAutoHide]="currentOptions?.previewArrowsAutoHide" [swipe]="currentOptions?.previewSwipe"
                       [fullscreen]="currentOptions?.previewFullscreen"
                       [forceFullscreen]="currentOptions?.previewForceFullscreen"
                       [closeOnClick]="currentOptions?.previewCloseOnClick"
                       [closeOnEsc]="currentOptions?.previewCloseOnEsc"
                       [keyboardNavigation]="currentOptions?.previewKeyboardNavigation"
                       [animation]="currentOptions?.previewAnimation" [autoPlay]="currentOptions?.previewAutoPlay"
                       [autoPlayInterval]="currentOptions?.previewAutoPlayInterval"
                       [autoPlayPauseOnHover]="currentOptions?.previewAutoPlayPauseOnHover"
                       [infinityMove]="currentOptions?.previewInfinityMove" [zoom]="currentOptions?.previewZoom"
                       [zoomStep]="currentOptions?.previewZoomStep" [zoomMax]="currentOptions?.previewZoomMax"
                       [zoomMin]="currentOptions?.previewZoomMin" [zoomInIcon]="currentOptions?.zoomInIcon"
                       [zoomOutIcon]="currentOptions?.zoomOutIcon" [actions]="currentOptions?.actions"
                       [rotate]="currentOptions?.previewRotate" [rotateLeftIcon]="currentOptions?.rotateLeftIcon"
                       [rotateRightIcon]="currentOptions?.rotateRightIcon" [download]="currentOptions?.previewDownload"
                       [downloadIcon]="currentOptions?.downloadIcon" [bullets]="currentOptions?.previewBullets"
                       (previewClose)="onPreviewClose()" (previewOpen)="onPreviewOpen()"
                       (activeChange)="previewSelect($event)"
                       [class.ngx-gallery-active]="previewEnabled"></ngx-gallery-preview>                       
</div>
