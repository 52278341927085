
<div class="container">
    <form class="search-form">
        <mat-form-field class="example-full-width" floatLabel="never">
        <input #searchElement matInput (keydown)="$event.keyCode == 13 ? $event.preventDefault() : null" (keyup)="searchByTerm($event.target.value)" autocomplete="off" placeholder="{{'search.placeholder' | translate}}">
        <mat-icon class="cm-search" matSuffix></mat-icon>
        </mat-form-field>
    </form>

    <div *ngIf="searchResults && searchResults.length" class="results-container">
        <div class="results-wrapper" *ngIf="searchResults">
            <cm-search-result 
                *ngFor="let result of searchResults"
                [result]="result"
                [lang]="translate.currentLang"
                (locationSelected)="openLocation($event)">
            </cm-search-result>
        </div>
    </div>
</div>