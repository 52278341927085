<div class="selector-topography"
routerLink="/topography" routerLinkActive="active"
(mouseenter) ="activateHover('topography')" (mouseleave)="activateHover(null)">
  <div class="not-selected-overlay" [@topographyBlur]="active"></div>
  <div class="label">{{'header.topography' | translate}}</div>
</div>
<div class="seperator"></div>
<div class="selector-topology"
routerLink="/topology" routerLinkActive="active"
(mouseenter) ="activateHover('topology')" (mouseleave)="activateHover(null)">
  <div class="not-selected-overlay" [@topologyBlur]="active"></div>
  <div class="label">{{'header.topology' | translate}}</div>
</div>
