<span *ngIf="data">
    <button mat-icon-button class="control" (click)="toggle()">
        <span [ngClass]="{'cm-square-image-big':!isOpen,'cm-square-close':isOpen}"></span>
    </button>
    <div class="animation-container" [@container]="isOpen">
        <div *ngIf="openedFirst" class="container" fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="50" class="image">
                <span class="cm-square-expand" (click)="showImage()"></span>
                <img [src]="isMobile ? data.data.mobileFull : data.data.full" (click)="showImage()" (contextmenu)="onRightClick($event)" />
                <ngx-gallery #gallery [options]="galleryOptions" [images]="galleryImages" (previewOpen)="previewOpened()" (previewClose)="previewClosed()"></ngx-gallery>
            </div>
            <cm-caption fxFlex="50" class="caption" [content]="lang === 'en' ? data.captionEn : data.captionDe" [lang]="lang"></cm-caption>
        </div>
    </div>
</span>
